.faq-header {
    font-size: 42px;
    border-bottom: 1px solid #ccc;
}

.faq-content {
    margin: 0 auto;
    width: 80%;
}

.faq-question {
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 0;
    margin-left: 0;
    border-bottom: 1px solid #ccc;
}

.panel-title {
    font-size: 24px;
    width: 100%;
    position: relative;
    margin: 0;
    display: block;
    cursor: pointer;
}

.panel-content {
    font-size: 20px;
    height: 0;
    overflow: hidden;
    z-index: -1;
    position: relative;
    opacity: 0;
}

.panel:checked~.panel-content {
    height: auto;
    opacity: 1;
    padding: 14px;
}

.plus {
    position: absolute;
    margin-top: 4px;
    z-index: 5;
    font-size: 42px;
    line-height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.panel {
    display: none;
}

.get-started {
    white-space: nowrap;
    appearance: button;
    backface-visibility: hidden;
    background-color: transparent;
    width: 1rem;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
        Ubuntu, sans-serif;
    font-size: 16px;
    height: 50px;
    line-height: 1.15;
    margin: 0;
    outline: none;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    border: rgba(255, 255, 255, 0.2) 1px solid;
    border-radius: 0.5rem;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0 0rem;
    width: 13rem;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.chevron-down {
    filter: invert(46%) sepia(23%) saturate(231%) hue-rotate(182deg) brightness(100%) contrast(90%);
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}

.chevron-up {
    transform: rotate(180deg);
    filter: invert(18%) sepia(9%) saturate(643%) hue-rotate(184deg) brightness(97%) contrast(93%);
}

.container {
    max-width: 1050px;
    width: 100%;
    margin: auto;
}

.navbar {
    width: 240%;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.navbar .menu-items {
    display: flex;
}

.navbar .nav-container li {
    list-style: none;
}

.navbar .nav-container a {
    text-decoration: none;
    color: #0e2431;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
    padding-right: 0;
}

.navbar .nav-container a:hover {
    font-weight: bolder;
}

.nav-container {
    display: block;
    height: 3rem;
}

.nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
}

.nav-container .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nav-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;
}

.nav-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
}

.navbar .menu-items {
    padding-top: 120px;
    height: 100vh;
    width: 100%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    background-color: white;
    z-index: 2;
}

.navbar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
}

.logo {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 1.2rem;
    color: #0e2431;
}

.nav-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked~.logo {
    display: none;
}

.nav-phone-item {
    display: none;

}

.acc-div-one {
    width: 40rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 3rem;
}

.div-create-wrap {
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    width: 42rem;
    gap: 2rem;
    margin: 9rem auto auto;
    background-color: white;
    inset: 0;
}



@media only screen and (max-width: 600px) {
    #try-it-out {
        width: 90%;
        margin: auto;
    }

    .nav-phone-item {
        display: block;
        width: 37.7%;
    }
}



@media only screen and (max-width: 700px) {
    .acc-div-one {
        width: 91%;
    }

    .div-create-wrap {
        width: 95%;
        padding: 0.7rem;
        height: auto;
    }

    .message {
        max-width: 80%;
    }
}

