.main-page-chat {
    width: 100%;
    height: 70rem;
}

/*                                    CHAT INPUT                                   */

.chat-input-wrap {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-items: space-between;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
}

.chat-input-wrap input {
    width: 100%;
    border-radius: 0.2rem;
    margin-top: auto;
    margin-bottom: 2rem;
    bottom: 0;
    border: 0;
    height: 3rem;
    bottom: 0;
    box-shadow: rgb(60 72 88 / 15%) 0px 0px 5px;
    margin-top: auto;
    margin-left: 2rem;
}

.chat-input-wrap input:focus {
    box-shadow: rgb(60 72 88 / 15%) 0px 0px 5px;
}

/*                                    HEADER                                   */

.header {
    position: absolute;
    display: fixed;
    top: 0;
    height: 4rem;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(60 72 88 / 15%) 0px 0px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-items p {
    padding: 0.3rem 1.2rem;
    border-radius: 1.2rem;
}

.header-items p:hover {
    background-color: #e8f4f7;
}

/*.selected-item {
    border: #6c7fe6 1px solid;
}*/

/*                                    BUTTON GET STARTED                       */

.get-started {
    appearance: button;
    backface-visibility: hidden;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
        Ubuntu, sans-serif;
    font-size: 100%;
    height: 2.5rem;
    line-height: 1.15;
    outline: none;
    overflow: hidden;
    padding: 1.5rem 1rem;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.get-started:disabled {
    cursor: default;
}

.get-started:focus {
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
        rgba(50, 151, 211, 0.3) 0 0 0 4px;
}

/*                      CHAT SECTION                     */

.messages {
    display: flex;
    flex-direction: column;
}

.chat-section {
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 0rem;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chat-background {
    width: 100%;
    height: 50rem;
    background-color: #ffffff;
    border: rgba(56, 56, 56, 0.1) 1px solid;
    box-shadow: rgb(40 52 68 / 15%) 0px 0px 3px;
    border-radius: 0.5rem;
    margin: auto;

    display: flex;
    flex-direction: column;
}

.chat-section-wrap {
    width: 60rem;
    height: 75%;
    margin: auto;
    margin-top: 8rem;
}

.button-chat-intput {
    appearance: button;
    backface-visibility: hidden;
    background-color: #383838;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
        Ubuntu, sans-serif;
    font-size: 100%;
    height: 3rem;
    line-height: 1.15;
    outline: none;
    overflow: hidden;
    padding: 0 0rem;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    opacity: 0.8;
    font-weight: 500;
    width: auto;
    padding: 0 2rem;
    margin-right: 2rem;
}

.message {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: fit-content;
    block-size: fit-content;
    max-width: 50%;
    margin: 2rem;
    margin-top: 1rem;
    text-align: left;
}

.ai-chat {
    align-items: left;
    justify-content: left;
    background-color: #f3f3f3;
}

.human-chat {
    position: relative;
    background-color: rgb(78, 137, 255);
    color: white;
    margin-left: auto;
}

/*                          CHAT SECTION HEADER                     */

.chat-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.button-chat-section {
    appearance: button;
    backface-visibility: hidden;
    background-color: #f3f3f3;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
        rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
        Ubuntu, sans-serif;
    font-size: 100%;
    height: 2.7rem;
    line-height: 1.15;
    outline: none;
    overflow: hidden;
    padding: 0 1rem;
    position: relative;
    text-align: center;
    text-transform: none;
    transform: translateZ(0);
    transition: all 0.2s, box-shadow 0.08s ease-in;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    opacity: 0.8;
    font-weight: 500;
}

@media only screen and (max-width: 1000px) {
    .chat-section-wrap {
        width: 100%;
    }
    .chat-background {
        width: 90%;
    }
    .chat-section-header {
        width: 90%;
        margin: auto;
        margin-bottom: 2rem;
    }
    .button-chat-intput {
        padding: 0 1rem;
        width: 5rem;
    }
    .main-page-chat {
        height: 50rem;
    }
}
