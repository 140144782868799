.login-cont {
    box-shadow: 0px 4px 30px 8px rgb(60 72 88 / 10%);
    -webkit-box-shadow: 0px 4px 30px 8px rgb(60 72 88 / 10%);
    padding: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: grid;
    place-items: center;
    margin: auto;
    margin-top: 15rem;
    width: 30rem;
    border-radius: 1rem;
}

.titles-log-sign {
    align-items: center;
    text-align: center;
    margin-right: auto;

    margin: auto;
}

.button-41 {
    background-color: #2746f3;

    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue",
        Arial, sans-serif;
    height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    padding-left: 2rem;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    margin-top: 1rem;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}

.button-41:hover {
    background-color: #3c56ee;
}

.button-42 {
    background-color: initial;
    /*background-image: linear-gradient(-45deg, #2eca8b, #4fe6a9);*/
    border-image-source: repeating-radial-gradient(
        ellipse farthest-corner at bottom left,
        #343cb4 0%,
        #e509ec 100%
    );

    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, -apple-system, system-ui, Roboto, "Helvetica Neue",
        Arial, sans-serif;
    height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    padding-left: 2rem;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    margin-top: 3rem;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
}
