@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: Poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.main-chat-page {
    margin-left: 20rem;
    margin: auto;
    margin-top: auto;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.modal-text-area {
    width: 90%;
    height: 12rem;
    margin-top: 0.5rem;
    border-radius: 0.25rem;
}

.button-2 {
    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    border-width: 0;
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    list-style: none;
    margin: 0;
    padding: 0px 12px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    height: 2.3rem;
}

.modul-settings {
    font-size: 3rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.hr-google {
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    width: 45%;
}

@media only screen and (max-width: 800px) {
    #modal-phone {
        min-width: 90%;
        width: 90%;
    }
    .modal-text-area {
        width: 100%;
        height: 18rem;
    }
    .modul-settings {
        font-size: 2rem;
    }
    #modal-confirm {
        min-width: 90%;
        width: 90%;
    }
}
