#root {
    font-family: poppins;
}

.create-button {
    border: 2px solid gray;
    width: 15rem;
    height: 6rem;
    font-size: 2rem;
    margin-bottom: 5rem;
}

.create-button:disabled {
    background-color: rgb(227, 119, 119);
    color: white;
}

#super {
    text-shadow: grey 2px 5px 10px;
}

.chat-page-wrap {
    width: 60rem;
    min-width: 40rem;
    display: block;
    justify-content: space-between;
    align-items: right;
    margin: auto;
    margin-top: 1rem;
}

@media only screen and (max-width: 1000px) {
    .chat-page-wrap {
        width: 100%;
        min-width: 10rem;
        padding: 0.5rem 2rem;
    }
}
